import { React } from "react";
import libraryIndex from "../assets/svg/library-index.svg";

function Root() {
  return (
    <div
      className="flex h-[100vh] w-full bg-no-repeat bg-cover bg-right"
      style={{ backgroundImage: `url(${libraryIndex})` }}
    >
      <div className="flex justify-center items-center w-full text-center flex-col">
        <div className="flex m-0 p-0 mt-0 mb-0 items-end justify-center">
          <p className="h-10 sm:h-14 text-primary font-Cormorant text-3xl sm:text-5xl border-b border-b-2 border-primary mb-0">
            Bem vindo à
          </p>
        </div>
        <div className="flex m-0 p-0 mt-0 mb-0 items-end justify-center">
          <p className="h-10 sm:h-14 text-white font-CormorantGar text-5xl sm:text-6xl">
            CAMILLO
          </p>
        </div>
        <div className="flex m-0 p-0 mt-0 mb-0 items-start justify-center">
          <p className="h-4 sm:h-14 text-white font-Cormorant text-4xl sm:text-5xl">
            ADVOGADOS
          </p>
        </div>
      </div>
    </div>
  );
}

export default Root;
